/**
 * External dependencies.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer'
import classnames from 'classnames';

/**
 * Internal dependencies.
 */
import Image from './../../image/index';
import { sanitize } from '../../../utils/functions';

import './style.scss';

/**
 * Home Logo Gallery Component.
 *
 * @param {Object} props Component props.
 *
 * @return {jsx}
 */
const LogoGallery = ({ meta }) => {
	const { title, logos } = meta;
	const [ref, inView] = useInView({
		threshold: 0.6,
		triggerOnce: true,
	});

	const containerClasses = classnames('home-sections home-logo-gallery', {
		'animate-section': inView
	});

	return (
		<section ref={ref} className={containerClasses}>
			<div className="row align-center">
				<div className="medium-10 small-12 column">
					<h2 className="home-logo-gallery__title" dangerouslySetInnerHTML={{ __html: sanitize(title) }} />
				</div>
			</div>

			<div className="row home-logo-gallery__lists">

				{logos && logos?.length > 0 && logos.map((image, index) => (
					<div key={index} className="medium-3 small-6 columns home-logo-gallery__list">
						<figure className="home-logo-gallery__figure">
							<Image {...image} className="home-logo-gallery__image" lazy={false} />
						</figure>
					</div>
				))}

			</div>
		</section>
	);

};

LogoGallery.propTypes = {
	meta: PropTypes.object,
};

LogoGallery.defaultProps = {
	data: {
		title: '',
		logos: []
	}
};

export default LogoGallery;
