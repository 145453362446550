/**
 * External dependencies.
 */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { range } from 'lodash';
import { useInView } from 'react-intersection-observer';
import classnames from 'classnames';

/**
 * Internal dependencies.
 */
import { sanitize } from '../../../utils/functions';

import './style.scss';

/**
 * Home Ticker Component.
 *
 * @param {Object} data Component props.
 *
 * @return {jsx}
 */
const Ticker = ({ meta, attrs }) => {
	// const { tickerText } = meta;
	const { text } = attrs;
	const [ref, inView] = useInView({
		threshold: 0.1,
	});

	const containerClasses = classnames('openweb-ticker', {
		'in-viewport': inView
	});

	return (
		<section ref={ref} className={containerClasses}>
			<div className="openweb-ticker__content">
				{range(5).map((none, index) => (
					<Fragment key={index}>
						<span dangerouslySetInnerHTML={{ __html: sanitize(text) }} />
						<span className="home-ticker__empty-space" />
					</Fragment>
				))}
			</div>
		</section>
	);

};

Ticker.propTypes = {
	meta: PropTypes.object,
};

Ticker.defaultProps = {
	meta: {},
};

export default Ticker;


