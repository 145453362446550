// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-old-js": () => import("./../../../src/pages/404-old.js" /* webpackChunkName: "component---src-pages-404-old-js" */),
  "component---src-templates-archive-index-js": () => import("./../../../src/templates/archive/index.js" /* webpackChunkName: "component---src-templates-archive-index-js" */),
  "component---src-templates-archive-single-index-js": () => import("./../../../src/templates/archive-single/index.js" /* webpackChunkName: "component---src-templates-archive-single-index-js" */),
  "component---src-templates-careers-index-js": () => import("./../../../src/templates/careers/index.js" /* webpackChunkName: "component---src-templates-careers-index-js" */),
  "component---src-templates-careers-position-index-js": () => import("./../../../src/templates/careers/position/index.js" /* webpackChunkName: "component---src-templates-careers-position-index-js" */),
  "component---src-templates-case-study-single-index-js": () => import("./../../../src/templates/case-study-single/index.js" /* webpackChunkName: "component---src-templates-case-study-single-index-js" */),
  "component---src-templates-community-index-js": () => import("./../../../src/templates/community/index.js" /* webpackChunkName: "component---src-templates-community-index-js" */),
  "component---src-templates-conversation-index-js": () => import("./../../../src/templates/conversation/index.js" /* webpackChunkName: "component---src-templates-conversation-index-js" */),
  "component---src-templates-data-index-js": () => import("./../../../src/templates/data/index.js" /* webpackChunkName: "component---src-templates-data-index-js" */),
  "component---src-templates-feed-index-js": () => import("./../../../src/templates/feed/index.js" /* webpackChunkName: "component---src-templates-feed-index-js" */),
  "component---src-templates-legal-privacy-child-index-js": () => import("./../../../src/templates/legal-privacy-child/index.js" /* webpackChunkName: "component---src-templates-legal-privacy-child-index-js" */),
  "component---src-templates-legal-privacy-index-js": () => import("./../../../src/templates/legal-privacy/index.js" /* webpackChunkName: "component---src-templates-legal-privacy-index-js" */),
  "component---src-templates-media-coverage-index-js": () => import("./../../../src/templates/media-coverage/index.js" /* webpackChunkName: "component---src-templates-media-coverage-index-js" */),
  "component---src-templates-newsroom-index-js": () => import("./../../../src/templates/newsroom/index.js" /* webpackChunkName: "component---src-templates-newsroom-index-js" */),
  "component---src-templates-page-index-js": () => import("./../../../src/templates/page/index.js" /* webpackChunkName: "component---src-templates-page-index-js" */),
  "component---src-templates-podcast-single-index-js": () => import("./../../../src/templates/podcast-single/index.js" /* webpackChunkName: "component---src-templates-podcast-single-index-js" */),
  "component---src-templates-podcasts-page-index-js": () => import("./../../../src/templates/podcasts-page/index.js" /* webpackChunkName: "component---src-templates-podcasts-page-index-js" */),
  "component---src-templates-powered-by-template-index-js": () => import("./../../../src/templates/powered-by-template/index.js" /* webpackChunkName: "component---src-templates-powered-by-template-index-js" */),
  "component---src-templates-preview-index-js": () => import("./../../../src/templates/preview/index.js" /* webpackChunkName: "component---src-templates-preview-index-js" */),
  "component---src-templates-quality-moderation-and-safety-index-js": () => import("./../../../src/templates/quality-moderation-and-safety/index.js" /* webpackChunkName: "component---src-templates-quality-moderation-and-safety-index-js" */),
  "component---src-templates-resources-index-js": () => import("./../../../src/templates/resources/index.js" /* webpackChunkName: "component---src-templates-resources-index-js" */),
  "component---src-templates-single-index-js": () => import("./../../../src/templates/single/index.js" /* webpackChunkName: "component---src-templates-single-index-js" */),
  "component---src-templates-spot-im-jobs-index-js": () => import("./../../../src/templates/spot-im-jobs/index.js" /* webpackChunkName: "component---src-templates-spot-im-jobs-index-js" */),
  "component---src-templates-state-of-online-conversations-index-js": () => import("./../../../src/templates/state-of-online-conversations/index.js" /* webpackChunkName: "component---src-templates-state-of-online-conversations-index-js" */),
  "component---src-templates-technology-index-js": () => import("./../../../src/templates/technology/index.js" /* webpackChunkName: "component---src-templates-technology-index-js" */),
  "component---src-templates-tei-calculator-index-js": () => import("./../../../src/templates/tei-calculator/index.js" /* webpackChunkName: "component---src-templates-tei-calculator-index-js" */),
  "component---src-templates-tei-result-index-js": () => import("./../../../src/templates/tei-result/index.js" /* webpackChunkName: "component---src-templates-tei-result-index-js" */),
  "component---src-templates-webinar-single-index-js": () => import("./../../../src/templates/webinar-single/index.js" /* webpackChunkName: "component---src-templates-webinar-single-index-js" */)
}

